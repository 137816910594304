


import React, { Fragment, useContext, useState } from "react";
import { Layout, Menu, Avatar } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../shared/auth-context";
import { UserOutlined } from "@ant-design/icons";
import { FaUser } from "react-icons/fa";

const { Sider } = Layout;

function Navbar() {
  const Navigate = useNavigate();
  const auth = useContext(AuthContext);
  const adminId = auth.adminId;
  const [collapsed, setCollapsed] = useState(false); // Add state for collapsed state

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const logoutHandler = () => {
    auth.logout();
    Navigate("/login");
  };

  return (
    <Fragment>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => { console.log(collapsed, type); }}
      >
        <div>
          <img
            className="img-fluid"
            src={process.env.PUBLIC_URL + "/img/default.png"}
            alt="Icon"
            style={{ width: "200px", padding: "20px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            padding: "10px",
            textTransform: "capitalize",
          }}
        >
          <Avatar
            style={{
              marginLeft: "20px",
              marginTop: "7px",
              backgroundColor: "orange",
              verticalAlign: "middle",
            }}
            size="medium"
            gap={1}
          >
            <UserOutlined />
          </Avatar>
          <h5 style={{ color: "white", marginTop: "10px", paddingLeft: "5px" }}>
            {auth.adminName}
          </h5>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1">
            <Link to={`/admin/${adminId}/`}>Home</Link>
          </Menu.Item>
          {auth.userlist && (
            <Menu.Item key="2">
              <Link to={`/admin/${auth.adminId}/userlist`}>UserList</Link>
            </Menu.Item>
          )}
          {auth.propertylist && (
            <Menu.Item key="3">
              <Link to={`/admin/${auth.adminId}/propertylist`}>
                PropertyList
              </Link>
            </Menu.Item>
          )}
          {auth.featuredlist && (
            <Menu.Item key="4">
              <Link to={`/admin/${auth.adminId}/featuredproperties`}>
                Featured Property
              </Link>
            </Menu.Item>
          )}
          {auth.verifiedlist && (
            <Menu.Item key="5">
              <Link to={`/admin/${auth.adminId}/verifiedproperties`}>
                Verified Property
              </Link>
            </Menu.Item>
          )}
          {auth.contactuslist && (
            <Menu.Item key="6">
              <Link to={`/admin/${auth.adminId}/contactus`}>Contact Us</Link>
            </Menu.Item>
          )}
           {auth.propertytypelist && (
            <Menu.Item key="7">
              <Link to={`/admin/${auth.adminId}/propertytype`}>Property Type</Link>
            </Menu.Item>
          )}
           {auth.propertydetails && (
            <Menu.Item key="8">
              <Link to={`/admin/${auth.adminId}/details`}>Details</Link>
            </Menu.Item>
          )}
          {
            auth.propertydetails && (
              <Menu.Item key="9">
                <Link to={`/admin/${auth.adminId}/cities`}>Cities</Link>
              </Menu.Item>
            )
          }
          <Menu.SubMenu key="Settings" title="Settings">
            <Menu.Item style={{ color: "white" }}>
              <Link to={`/admin/${auth.adminId}/changepassword`}>
                ChangePassword
              </Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item key="10">
            <Link to onClick={logoutHandler}>
              LogOut
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </Fragment>
  );
}

export default Navbar;
