import React, { useState, useContext, useEffect } from "react";
import { FaBed, FaBath, FaCar, FaMapMarkerAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./AddProperty.css";
import Carousel from "react-bootstrap/Carousel";
import { Modal, Collapse, Switch } from "antd";
import axios from "axios";
import { Formik, isEmptyArray } from "formik";
import { AuthContext } from "../../shared/auth-context";

import Chip from "@material-ui/core/Chip";

import { Upload } from "antd";

const { Panel } = Collapse;

function AddProperty() {
  const regex = /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm;
  const auth = useContext(AuthContext);
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const { id } = useParams();
  const [property, setProperty] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [alldetails, setAllDetails] = useState([]);
  const [allpropertytypes, setAllPropertyType] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [propertyPrimaryDetaile, setPropertyPrimaryDetaile] = useState([]);
  const [propertySecondaryDetaile, setPropertySecondaryDetaile] = useState([]);
  const [propertyServices, setPropertyServices] = useState([]);
  const [propertyEntertainment, setPropertyEntertainment] = useState([]);
  const [propertyNearByLandmarks, setPropertyNearByLandmarks] = useState([]);
  const [count, setCount] = useState("1");
  const [selectedOption, setSelectedOption] = useState("");
  const [detailType, setDetailType] = useState();

  const onChange = (key) => {};
  const navigate = useNavigate();
  const byear = new Date().getFullYear() - 20;
  const years = Array.from(new Array(20), (val, index) => index + byear);

  const [existingPropertyImages, setExistingPropertyImages] = useState([]);
  const [newPropertyImages, setNewPropertyImages] = useState([]);
  const [selectedCountValue, setSelectedCountValue] = useState("");
  const [isParsedArraySet, setIsParsedArraySet] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/property/single/${id}`
        );
        setProperty(data[0]);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/property/allpropertytypes`
      );
      setAllPropertyType(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/property/alldetails`
      );
      setAllDetails(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/property/cities`
        );

        setCityData(data);
      } catch (error) {
        // Handle the error
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (property?.images) {
      setExistingPropertyImages(property?.images);
    }
  }, [property?.images]);
  const parsedArray = property?.propertyPrimaryDetaile?.map((item) =>
    JSON.parse(item)
  );

  useEffect(() => {
    if (parsedArray && parsedArray.length > 0 && !isParsedArraySet) {
      setPropertyPrimaryDetaile(parsedArray);
      setIsParsedArraySet(true);
    }
  }, [parsedArray, isParsedArraySet]);
  useEffect(() => {
    if (property?.propertySecondaryDetaile) {
      setPropertySecondaryDetaile(property?.propertySecondaryDetaile);
    }
  }, [property?.propertySecondaryDetaile]);
  useEffect(() => {
    if (property?.propertyServices) {
      setPropertyServices(property?.propertyServices);
    }
  }, [property?.propertyServices]);

  useEffect(() => {
    if (property?.propertyEntertainment) {
      setPropertyEntertainment(property?.propertyEntertainment);
    }
  }, [property?.propertyEntertainment]);

  useEffect(() => {
    if (property?.propertyNearByLandmarks) {
      setPropertyNearByLandmarks(property?.propertyNearByLandmarks);
    }
  }, [property?.propertyNearByLandmarks]);

  const handlePrimaryDetails = (detailsType, detailsCount) => {
    if (detailsCount) {
      const existingDetail = propertyPrimaryDetaile.find(
        (detail) => detail.detailType === detailsType
      );

      if (existingDetail) {
        existingDetail.detailTypeCount = detailsCount;
        setPropertyPrimaryDetaile([...propertyPrimaryDetaile]);
      } else {
        const newDetail = {
          detailType: detailsType,
          detailTypeCount: detailsCount,
        };
        setPropertyPrimaryDetaile((prevDetails) => [...prevDetails, newDetail]);
      }
    } else {
      setPropertyPrimaryDetaile((prevDetails) =>
        prevDetails.filter((d) => d.detailType !== detailsType)
      );
      setSelectedOption((prevDetail) =>
        prevDetail.filter((d) => d !== detailsType)
      );
    }
  };

  const handleSecondaryDetails = (detailtype) => {
    if (propertySecondaryDetaile.includes(detailtype)) {
      return;
    } else {
      setPropertySecondaryDetaile((prevDetails) => [
        ...prevDetails,
        detailtype,
      ]);
    }
  };

  const handleServices = (propertyService) => {
    if (propertyServices.includes(propertyService)) {
      return;
    } else {
      setPropertyServices((prevService) => [...prevService, propertyService]);
    }
  };

  const handleEntertainment = (entertainmentService) => {
    if (propertyEntertainment.includes(entertainmentService)) {
      return;
    } else {
      setPropertyEntertainment((prevDetails) => [
        ...prevDetails,
        entertainmentService,
      ]);
    }
  };

  const handleNearByLandmarks = (nearByLandmarks) => {
    if (propertyNearByLandmarks.includes(nearByLandmarks)) {
      return;
    } else {
      setPropertyNearByLandmarks((prevDetails) => [
        ...prevDetails,
        nearByLandmarks,
      ]);
    }
  };

  const handleCount = (count, detail) => {
    setCount(count);
    if (count) {
      setSelectedOption((prevDetails) => [...prevDetails, detail]);
      handlePrimaryDetails(detail, count);
    } else {
      setSelectedOption((prevDetails) => [...prevDetails, ""]);
      handlePrimaryDetails(detail, count);
    }
  };

  const handlerArray = [
    null,
    handleSecondaryDetails,
    handleServices,
    handleEntertainment,
    handleNearByLandmarks,
  ];

  const setDetail = (type, index) => {
    if (index >= 1 && index < handlerArray.length) {
      handlerArray[index](type);
    }
  };
  console.log(
    propertyPrimaryDetaile,
    propertySecondaryDetaile,
    propertyServices,
    propertyEntertainment,
    propertyNearByLandmarks
  );

  const handleDelete = (chipToDelete) => () => {
    let exist;
    setPropertyPrimaryDetaile((prevChips) =>
      prevChips.filter((c) => c.detailType !== chipToDelete)
    );

    propertySecondaryDetaile.forEach((detail, i) => {
      setPropertySecondaryDetaile((prevChips) =>
        prevChips.filter((c) => c.detailType !== chipToDelete)
      );

      if (detail === chipToDelete) {
        propertySecondaryDetaile.splice(i, 1);
        exist = true;
        return true; // stop searching
      }
      return exist;
    });
    propertyServices.forEach((service, i) => {
      if (service === chipToDelete) {
        propertyServices.splice(i, 1);
        exist = true;
        return true; // stop searching
      }
      return exist;
    });
    propertyEntertainment.forEach((entertainment, i) => {
      if (entertainment === chipToDelete) {
        propertyEntertainment.splice(i, 1);
        exist = true;
        return true; // stop searching
      }
      return exist;
    });
    propertyNearByLandmarks.forEach((landmark, i) => {
      if (landmark === chipToDelete) {
        propertyNearByLandmarks.splice(i, 1);
        exist = true;
        window.location.reload();
        return true; // stop searching
      }
      return exist;
    });
  };
  const uploadHandle = ({ fileList }) => {
    setNewPropertyImages(fileList);
  };

  const handleImageClick = () => {
    // setClickedImage(image);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDeleteImage = (index) => {
    // Filter out the image at the specified index
    const updatedImages = existingPropertyImages.filter(
      (image, i) => i !== index
    );
    // Update the existingPropertyImages state with the updated array
    setExistingPropertyImages(updatedImages);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  console.log(property);
  return (
    <div>
      <div class=" py-5">
        <div class="container">
          <div className="main">
            <Formik
              initialValues={{
                propertypurpose: property?.propertypurpose,
                propertytype: property?.propertytype,
                propertysubtype: property?.propertysubtype,
                propertysize: property?.propertysize,
                propertysizetype: property?.propertysizetype,
                propertyprice: property?.propertyprice,
                propertytitle: property?.propertytitle,
                propertydescription: property?.propertydescription,
                propertycity: property?.propertycity,
                propertycityarea: property?.propertycityarea,
                propertylocation: property?.propertylocation,
                propertybuiltyear: property?.propertybuiltyear,
                permalink: property?.permalink,
                propertynoofbedroom: property?.propertynoofbedroom,
                propertynoofbathroom: property?.propertynoofbathroom,
                propertynoofcarparking: property?.propertynoofcarparking,
                existingimages: property?.images,
                newimages: [],
                propertyPrimaryDetaile: property?.propertyPrimaryDetaile?.map(
                  (item) => JSON.parse(item)
                ),

                propertySecondaryDetaile: property?.propertySecondaryDetaile,
                propertyServices: property?.propertyServices,
                propertyEntertainment: property?.propertyEntertainment,
                propertyNearByLandmarks: property?.propertyNearByLandmarks,
                numberoffloors: property?.numberoffloors,
                unitfloor: property?.unitfloor,
                name: property?.name,
                phonenumber: property?.phonenumber,
                featured: property?.featured,
                likecount: property?.likecount,
                userId: auth?.userId,
              }}
              // validate={(values) => {
              //   const errors = {};
              //   if (!values.propertypurpose) {
              //     errors.propertypurpose = "Property Purpose Required";
              //   }

              //   if (!values.propertytype) {
              //     errors.propertytype = "Property Type Required";
              //   }
              //   if (!values.propertysubtype) {
              //     errors.propertysubtype = "Required";
              //   }
              //   if (!values.propertysize) {
              //     errors.propertysize = "Property Size Required";
              //   }
              //   if (values.propertysizetype === "") {
              //     errors.propertysizetype = "Size Type Required";
              //   }
              //   if (!values.propertyprice) {
              //     errors.propertyprice = "Property Price Required";
              //   }
              //   if (!values.propertytitle) {
              //     errors.propertytitle = "Property Title Required";
              //   }
              //   if (!values.propertydescription) {
              //     errors.propertydescription = "Property Description Required";
              //   }
              //   if (!values.propertylocation) {
              //     errors.propertylocation = "Property Location Required";
              //   }
              //   if (!values.phonenumber) {
              //     errors.phonenumber = "Required";
              //   } else if (!regex.test(values.phonenumber)) {
              //     errors.phonenumber = "Invalid Phone No";
              //   }
              //   if (!values.name) {
              //     errors.name = "Name Required";
              //   }
              //   return errors;
              // }}
              onSubmit={async (values, actions) => {
                console.log(values);
                const formData = new FormData();
                formData.append("propertypurpose", values.propertypurpose);
                formData.append("propertytype", values.propertytype);
                formData.append("propertysubtype", values.propertysubtype);
                formData.append("propertysize", values.propertysize);
                formData.append("propertysizetype", values.propertysizetype);
                formData.append("propertyprice", values.propertyprice);
                formData.append("propertytitle", values.propertytitle);
                formData.append(
                  "propertydescription",
                  values.propertydescription
                );
                formData.append("propertycity", values.propertycity);
                formData.append("propertycityarea", values.propertycityarea);
                formData.append("propertylocation", values.propertylocation);
                formData.append("propertybuiltyear", values.propertybuiltyear);
                formData.append("permalink", values.permalink);

                formData.append(
                  "propertynoofbedroom",
                  values.propertynoofbedroom
                );
                formData.append(
                  "propertynoofbathroom",
                  values.propertynoofbathroom
                );
                formData.append(
                  "propertynoofcarparking",
                  values.propertynoofcarparking
                );
                propertyPrimaryDetaile.map((detail) => {
                  formData.append(
                    "propertyPrimaryDetaile",
                    JSON.stringify(detail)
                  );
                });
                console.log(propertySecondaryDetaile);
                propertySecondaryDetaile.map((detail) => {
                  formData.append(
                    "propertySecondaryDetaile",
                    (values.propertySecondaryDetaile = detail)
                  );
                });
                console.log(propertyServices);
                propertyServices.map((service) => {
                  formData.append(
                    "propertyServices",
                    (values.propertyServices = service)
                  );
                });

                propertyEntertainment.map((entertainment) => {
                  formData.append(
                    "propertyEntertainment",
                    (values.propertyEntertainment = entertainment)
                  );
                });

                propertyNearByLandmarks.map((nearby) => {
                  formData.append(
                    "propertyNearByLandmarks",
                    (values.propertyNearByLandmarks = nearby)
                  );
                });

                formData.append("numberoffloors", values.numberoffloors);
                formData.append("unitfloor", values.unitfloor);
                formData.append("name", values.name);
                formData.append("phonenumber", values.phonenumber);
                formData.append(
                  "propertyaddeddate",
                  (values.propertyaddeddate = formattedDate)
                );
                formData.append("featured", (values.featured = false));
                formData.append("likecount", values.likecount);
                formData.append("userId", values?.userId);
                existingPropertyImages.map((image) => {
                  formData.append("existingimages", image);
                });
                // formData.append("existingimages", values.existingimages)
                newPropertyImages.map((image) => {
                  formData.append("newimages", image.originFileObj);
                });
                console.log(formData);
                try {
                  const response = await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_BASE_URL}property/update/${id}`,
                    data: formData,

                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  });
                  if (response.data.message) {
                    Modal.warning({
                      title: response.data.message,
                    });
                  } else {
                    if (response.status === 200) {
                      Modal.success({
                        title: response.data,
                      });
                      navigate(`/admin/${id}/propertylist`);
                    }
                  }
                } catch (err) {
                  console.log(err);
                  // alert(err.response.data.message, err.response.status);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div>
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="edit_property">
                      <div className="form-field propertytype">
                        <label>Select Property Purpose</label>
                        <select
                          name="propertypurpose"
                          placeholder="Choose Property Purpose."
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.propertypurpose}
                        >
                          <option value="">Select</option>
                          <option value="sale">Sell</option>
                          <option value="rent">Rent</option>
                        </select>
                      </div>
                      <div className="form-field propertytype">
                        <label>Select Property Type</label>
                        <select
                          name="propertytype"
                          placeholder="Choose Property Type."
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.propertytype}
                        >
                          <option value="">Select</option>
                          {allpropertytypes.map((type) => (
                            <option value={type.propertytype}>
                              {type.propertytype}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-field propertytype">
                        <label>Select Property SubType</label>
                        {allpropertytypes.map((type) => {
                          if (type.propertytype === values.propertytype) {
                            return (
                              <select
                                name="propertysubtype"
                                placeholder="Choose Property SubType."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.propertysubtype}
                              >
                                <option value="">Select</option>
                                {type.propertysubtype.map((subtype) => (
                                  <option value={subtype}>{subtype}</option>
                                ))}
                              </select>
                            );
                          }
                        })}
                      </div>
                      <div
                        className="form-field propertysize"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label>Enter Size</label>

                        <div style={{ display: "flex", width: "100%" }}>
                          <input
                            type="number"
                            class="form-control"
                            required
                            id="propertysize"
                            name="propertysize"
                            placeholder="Enter Size"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.propertysize}
                          />

                          <select
                            name="propertysizetype"
                            style={{
                              color: "#2E307D",
                              borderTopRightRadius: "5px",
                              borderBottomRightRadius: "5px",

                              border: "1px solid #CED4DA",
                            }}
                            placeholder="Choose size type."
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.propertysizetype}
                          >
                            <option value="">Select</option>
                            <option value="Marla">Marla</option>
                            <option value="feet">Feet</option>
                            <option value="yard">Yard</option>
                            <option value="meter">Meter</option>
                            <option value="kanal">Kanal</option>
                          </select>
                        </div>
                        {/* <p style={{ color: "red" }}>
                          {(errors.propertysize &&
                            touched.propertysize &&
                            errors.propertysize) ||
                            (errors.propertysizetype &&
                              touched.propertysizetype &&
                              errors.propertysizetype)}
                        </p> */}
                      </div>
                      <div className="form-field propertydescription">
                        <label>price</label>
                        <input
                          type="number"
                          class="form-control"
                          id="propertyprice"
                          name="propertyprice"
                          placeholder="Price"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.propertyprice}
                        />
                        {/* <p style={{ color: "red" }}>
                          {errors.propertyprice &&
                            touched.propertyprice &&
                            errors.propertyprice}
                        </p> */}
                      </div>
                      <div className="form-field ">
                        <label>Title</label>
                        <input
                          type="text"
                          class="form-control"
                          id="propertytitle"
                          name="propertytitle"
                          placeholder="Enter Title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.propertytitle}
                        />
                      </div>
                      <div className="form-field propertydescription">
                        <label>Description</label>
                        <textarea
                          type="text"
                          rows="7"
                          class="form-control"
                          id="propertydescription"
                          name="propertydescription"
                          placeholder="Description"
                          style={{ height: "200px" }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.propertydescription}
                        ></textarea>
                        {/* <p style={{ color: "red" }}>
                          {errors.propertydescription &&
                            touched.propertydescription &&
                            errors.propertydescription}
                        </p> */}
                      </div>
                      <div className="form-field propertylocation">
                        <label for="propertylocation">Location:</label>
                        <div className=" propertytype">
                          <select
                            style={{ fontSize: "16px" }}
                            name="propertycity"
                            placeholder="Select city."
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.propertycity}
                          >
                            <option value=" ">Select</option>
                            {cityData.map((city) => (
                              <option value={city.city}>{city.city}</option>
                            ))}
                          </select>
                        </div>
                        <div className="propertytype">
                          {cityData.map((city) => {
                            if (city.city === values.propertycity) {
                              return (
                                <select
                                  style={{ fontSize: "16px" }}
                                  name="propertycityarea"
                                  placeholder="Choose city Areas."
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.propertycityarea}
                                >
                                  <option value=" ">Select</option>
                                  {city.cityareas.map((cityarea) => (
                                    <option value={cityarea.areaText}>
                                      {cityarea.areaText}
                                    </option>
                                  ))}
                                </select>
                              );
                            }
                          })}
                        </div>
                        <div>
                          <input
                            type="text"
                            class="form-control"
                            id="propertylocation"
                            name="propertylocation"
                            placeholder="Location"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.propertylocation}
                          />
                        </div>
                        {/* <p style={{ color: "red" }}>
                          {errors.propertylocation &&
                            touched.propertylocation &&
                            errors.propertylocation}
                        </p> */}
                      </div>

                      <div className="form-field simpledetails">
                        <div className="noofbedroom">
                          <label>No.of Bedrooms</label>
                          <select
                            className="numberofbedroomcounter"
                            name="propertynoofbedroom"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.propertynoofbedroom}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10+">10+</option>
                          </select>
                        </div>
                        <div className="noofbathroom">
                          <label>No.of Bathrooms</label>
                          <select
                            className="numberofbedroomcounter"
                            name="propertynoofbathroom"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.propertynoofbathroom}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10+">10+</option>
                          </select>
                        </div>
                        <div className="noofcarparking">
                          <label>No.of Car Parking</label>
                          <select
                            className="numberofbedroomcounter"
                            name="propertynoofcarparking"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.propertynoofcarparking}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10+">10+</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-field ">
                        <label>Name of Property Owner</label>
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          name="name"
                          placeholder="Enter Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                      </div>
                      <div className="form-field ">
                        <label>Contact of property owner</label>
                        <input
                          type="text"
                          class="form-control"
                          id="phonenumber"
                          name="phonenumber"
                          placeholder="Phone No."
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phonenumber}
                        />
                      </div>
                      <div className="form-field ">
                        <label>Number of Likes on Property</label>
                        <input
                          type="number"
                          class="form-control"
                          id="likecount"
                          name="likecount"
                          placeholder="Number of Likes."
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.likecount}
                        />
                      </div>
                      <div>
                        <h6>Add Images</h6>
                        <div
                          className="propertyimage"
                          onClick={handleImageClick}
                        >
                          <div style={{ padding: "10px" }}>
                            {existingPropertyImages?.map((image, index) => (
                              <div className="thumbnail-wrapper" key={index}>
                                <img
                                  src={`${process.env.REACT_APP_BASE_URL}/${image}`}
                                  alt={`Image ${index + 1}`}
                                  className="thumbnail-image"
                                />
                                <button
                                  type="button"
                                  style={{
                                    padding: "0px",
                                    width: "20px",
                                    height: "20px",
                                    textAlign: "center",
                                    position: "absolute",
                                    transform: "translate(-50%, -50%)",
                                    top: "10%",
                                    left: "84%",
                                  }}
                                  className="delete-button"
                                  onClick={() => handleDeleteImage(index)}
                                >
                                  X
                                </button>
                              </div>
                            ))}
                          </div>

                          <div>
                            <Modal
                              // title="Property Details"
                              open={isModalOpen}
                              onOk={handleOk}
                              onCancel={handleCancel}
                              width={750}
                              bodyStyle={{
                                height: "auto",

                                marginLeft: "0px",
                                paddingLeft: "0px",
                              }}
                              okText={"Add"}
                              cancelText={"Back"}
                              footer={null}
                            >
                              <div className="slider">
                                <Carousel>
                                  {existingPropertyImages?.map((image) => {
                                    return (
                                      <Carousel.Item>
                                        <img
                                          className="d-block w-100"
                                          src={`${process.env.REACT_APP_BASE_URL}/${image}`}
                                          alt="items images"
                                        />
                                      </Carousel.Item>
                                    );
                                  })}
                                </Carousel>
                              </div>
                            </Modal>
                          </div>
                        </div>
                        <div class="row g-4">
                          <div class="col-md-12">
                            <div class="wow fadeInUp" data-wow-delay="0.5s">
                              <div>
                                <Upload.Dragger
                                  multiple
                                  accept=".png,.jpg,.jpeg"
                                  onChange={uploadHandle}
                                  // beforeUpload={handleBeforeUpload}
                                >
                                  <p className="ant-upload-text">
                                    Drag file here OR
                                    <br />
                                    <button
                                      className="btn btn-primary py-2"
                                      type="button"
                                    >
                                      Click Upload
                                    </button>
                                  </p>
                                  {/* {defaultImage ? (
                                    <img
                                      src={defaultImage}
                                      alt="Default"
                                      style={{ width: "100%", height: "100%" }}
                                    />
                                  ) : (
                                    <>
                                      {/* <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                      </p> */}

                                  {/* </> */}
                                </Upload.Dragger>

                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-field simpledetails">
                        <div className="noofbedroom">
                          <label>Property Built Year</label>
                          <select
                            className="numberofbedroomcounter"
                            name="propertybuiltyear"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.propertybuiltyear}
                          >
                            <option value="">Select</option>
                            {(() => {
                              return years.map((year, index) => {
                                return (
                                  <option key={`year${index}`} value={year}>
                                    {year}
                                  </option>
                                );
                              });
                            })()}
                          </select>
                        </div>
                      </div>
                      <div className="form-field simpledetails">
                        <div className="noofbedroom">
                          <label>Number of Floors In The Building</label>
                          <select
                            className="numberofbedroomcounter"
                            name="numberoffloors"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.numberoffloors}
                          >
                            <option selected value="">
                              Select
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-field simpledetails">
                        <div className="noofbedroom">
                          <label>Which Floor Is Your Unit On</label>
                          <select
                            className="numberofbedroomcounter"
                            name="unitfloor"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.unitfloor}
                          >
                            <option selected value="">
                              Select
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                        </div>
                      </div>

                      <div className="primarydetails">
                        <h5>All Detail</h5>
                        <div>
                          {propertyPrimaryDetaile?.length > 0 &&
                            propertyPrimaryDetaile?.map((value) => {
                              console.log(propertyPrimaryDetaile);
                              return (
                                <Chip
                                  label={`${value?.detailTypeCount} ${value?.detailType} `}
                                  style={{
                                    backgroundColor: "#2E307D",
                                    color: "#ffffff",
                                    padding: "20px",
                                    marginRight: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "10px",
                                  }}
                                  onDelete={handleDelete(value.detailType)}
                                />
                              );
                            })}
                          {propertySecondaryDetaile?.length > 0 &&
                            propertySecondaryDetaile?.map((value) => (
                              <Chip
                                label={`${value} `}
                                style={{
                                  backgroundColor: "#2E307D",
                                  color: "#ffffff",
                                  padding: "20px",
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "10px",
                                }}
                                onDelete={handleDelete(value)}
                              />
                            ))}
                          {propertyServices?.length > 0 &&
                            propertyServices?.map((value) => (
                              <Chip
                                label={`${value} `}
                                style={{
                                  backgroundColor: "#2E307D",
                                  color: "#ffffff",
                                  padding: "20px",
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "10px",
                                }}
                                onDelete={handleDelete(value)}
                              />
                            ))}
                          {propertyEntertainment?.length > 0 &&
                            propertyEntertainment?.map((value) => (
                              <Chip
                                label={`${value} `}
                                style={{
                                  backgroundColor: "#2E307D",
                                  color: "#ffffff",
                                  padding: "20px",
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "10px",
                                }}
                                onDelete={handleDelete(value)}
                              />
                            ))}
                          {propertyNearByLandmarks?.length > 0 &&
                            propertyNearByLandmarks?.map((value) => (
                              <Chip
                                label={`${value} `}
                                style={{
                                  backgroundColor: "#2E307D",
                                  color: "#ffffff",
                                  padding: "20px",
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "10px",
                                }}
                                onDelete={handleDelete(value)}
                              />
                            ))}
                        </div>
                        <div
                          className="select-row"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {alldetails.map((detail, index) => (
                            <div key={index}>
                              <div
                                className="primarydetails"
                                style={{
                                  // backgroundColor: "red",
                                  // width:"90%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <select
                                  className="form-field"
                                  style={{
                                    padding: "10px 60px 10px 10px",
                                    width: "80%",
                                    border: "1px solid #ced4da",
                                    borderRadius: "5px",
                                  }}
                                  onChange={(e) => {
                                    setDetailType(e.target.value);
                                    // setDetail(subtype, index);
                                  }}
                                  header={<div>{detail.detailtype}</div>}
                                >
                                  <option value="">{detail.detailtype}</option>
                                  {detail.detailsubtype.map((subtype) => (
                                    <option
                                      // onClick={() => {
                                      //   setDetail(subtype, index);
                                      // }}
                                      value={subtype}
                                      key={subtype}
                                    >
                                      {subtype}
                                    </option>
                                  ))}
                                </select>
                                {index === 0 && (
                                  <div>
                                    <select
                                      style={{
                                        marginLeft: "2px",
                                        // padding: "1px 60px 1px 20px",
                                        border: "1px solid #ced4da",
                                        borderRadius: "5px",
                                        width: "100%",
                                        height: "41px",
                                        alignItems: "center",
                                      }}
                                      onChange={(e) => {
                                        setSelectedCountValue(e.target.value);
                                      }}
                                      value={selectedCountValue}
                                      className="select-half-width"
                                    >
                                      <option value="">Select an option</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10+">10+</option>
                                    </select>
                                  </div>
                                )}
                                <div>
                                  <button
                                    style={{
                                      padding: "10px 50px 30px 25px",
                                      border: "1px solid #ced4da",
                                      borderRadius: "5px",
                                    }}
                                    className="details"
                                    type="button"
                                    onClick={() => {
                                      if (index === 0) {
                                        handleCount(
                                          selectedCountValue,
                                          detailType
                                        );
                                      }

                                      setDetail(detailType, index);
                                      console.log(detailType, index);
                                    }}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="Submit">
                        <button
                          className="btn btn-primary py-2  action_submit_buttons"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProperty;
