import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import "datatables.net-dt/css/jquery.dataTables.css";
import { AuthContext } from "../../shared/auth-context";
import axios from "axios";
import { Link } from "react-router-dom";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { Switch } from "antd";
import "antd/dist/antd.css";

const MyDataTable = () => {
  const auth = useContext(AuthContext);
  console.log(auth);
  const [property, setProperty] = useState([]);
  const [verified, setVerified] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [propertyItems, setPropertyItems] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/property/admin`
    );
    setProperty(data);
  };

  if (property.length === 0) {
    return (
      <div
        id="spinner"
        style={{ backdropFilter: "blur(4px)" }}
        class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          class="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const mapPropertyData = () =>
    property.map((item) => ({
      id: item.id,
      propertytitle: item.propertytitle,
      propertypurpose: item.propertypurpose,
      propertytype: item.propertytype,
      propertysubtype: item.propertysubtype,
      propertysize: item.propertysize,
      propertyprice: item.propertyprice,
      action: (
        <div className="action-buttons">
          <Link to={`/admin/${auth.adminId}/viewproperty/${item.id}`}>
            <EyeOutlined
              style={{
                color: "green",
                marginRight: 12,
                fontSize: 24,
              }}
            />
          </Link>
          <Link
            to={`/admin/${auth.adminId}/propertyreport/editpropertyform/${item.id}`}
          >
            <EditOutlined style={{ color: "blue", fontSize: 24 }} />
          </Link>
          <DeleteOutlined
            onClick={() => handleDelete(item.id)}
            style={{ color: "red", marginLeft: 12, fontSize: 24 }}
          />
        </div>
      ),
      verified: (
        <Switch
          checked={item.verified}
          onChange={() => handleVerification(item)}
        />
      ),
      featured: (
        <Switch checked={item.featured} onChange={() => handleFeature(item)} />
      ),
    }));

  const handleVerification = async (item) => {
    try {
      Modal.confirm({
        title: "Verify Property",
        content: "Do you want to update the verification of this property?",
        okText: "Yes",
        cancelText: "No",
        okButtonProps: {
          // Add this to style the OK button
          style: { width: "80px" },
        },
        cancelButtonProps: {
          style: { width: "80px" },
        },
        onOk: async () => {
          const verified = !item.verified; // Toggle the verified value

          // Perform the property verification
          const response = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}/property/verification/${item.id}`,
            { verified }
          );
          if (response.data.message) {
            Modal.warning({
              title: response.data.message,
            });
          } else {
            if (response.status === 200) {
              Modal.success({
                title: response.data,
                onOk: () => {
                  const updatedItem = { ...item, verified };
                  const updatedPropertyItems = property.map((prop) =>
                    prop.id === item.id ? updatedItem : prop
                  );
                  setProperty(updatedPropertyItems);
                },
              });
              fetchData();
            }
          }
        },
      });
    } catch (err) {
      const message =
        err.response.data.message || "Something went wrong, please try again!";
      Error(message);
      alert(err.response.data.message, err.response.status);
    }
  };

  const handleFeature = async (item) => {
    try {
      Modal.confirm({
        title: "Feature Property",
        content: "Do you want to update the featuring this property?",
        okText: "Yes",
        cancelText: "No",
        okButtonProps: {
          // Add this to style the OK button
          style: { width: "80px" },
        },
        cancelButtonProps: {
          style: { width: "80px" },
        },
        onOk: async () => {
          const featured = !item.featured; // Toggle the featured value

          // Perform the property feature operation
          const response = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}/property/featured/${item.id}`,
            { featured }
          );
if(response.data.message) {
  Modal.warning({
    title:response.data.message
  })
} else {
  if (response.status === 200) {
    Modal.success({
      title: response.data,
      onOk: () => {
        const updatedItem = { ...item, featured };
        const updatedPropertyItems = property.map((prop) =>
          prop.id === item.id ? updatedItem : prop
        );
        setProperty(updatedPropertyItems);
      },
    });
    fetchData();
  }
}
          
        },
      });
    } catch (err) {
      const message =
        err.response.data.message || "Something went wrong, please try again!";
      Error(message);
      alert(err.response.data.message, err.response.status);
    }
  };
  const handleDelete = (itemId) => {
    Modal.confirm({
      title: "Confirmation",
      content: "Are you sure you want to delete this user?",
      okText: "Yes",
      okCancel: "No",
      okButtonProps: {
        // Add this to style the OK button
        style: { width: "80px" },
      },
      cancelButtonProps: {
        style: { width: "80px" },
      },
      onOk: () => {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/property/${itemId}`)
          .then((res) => {
            if (res.status) {
              Modal.success({
                title: "Success",
                content: "Property Deleted successfully.",
              });
              fetchData();
            }
          })
          .catch((error) => {
            alert("error ", error);
          });
      },
      onCancel: () => {
        // Handle cancel logic if needed
      },
    });
  };

  const data = {
    columns: [
      // {
      //   label: "ID",
      //   field: "id",
      //   sort: "asc",
      //   width: "70px",
      // },
      {
        label: "Title",
        field: "propertytitle",
        sort: "asc",
        width: "300px",
      },
      {
        label: "Purpose",
        field: "propertypurpose",
        sort: "asc",
        width: "200px",
      },
      {
        label: "Type",
        field: "propertytype",
        sort: "asc",
        width: "200px",
      },
      {
        label: "SubType",
        field: "propertysubtype",
        sort: "asc",
        width: "200px",
      },
      {
        label: "Size",
        field: "propertysize",
        sort: "asc",
        width: "200px",
      },
      {
        label: "Price",
        field: "propertyprice",
        sort: "asc",
        width: "200px",
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: "150px",
      },
      {
        label: "Verified",
        field: "verified",
        sort: "disabled",
        width: "100px",
      },
      {
        label: "Featured",
        field: "featured",
        sort: "disabled",
        width: "100px",
      },
    ],
    rows: mapPropertyData(),
  };

  const options = {
    searchBox: true,
    searchBoxClass: "form-control",
    searchLabel: "Search",
    searchText: "",
    filterText: "Filter",
    responsive: true,
    responsiveSm: true,
    responsiveMd: true,
    responsiveLg: true,
    responsiveXl: true,
    paginationLabel: ["Previous", "Next"],
  };

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
        fontSize: "18px",
        whiteSpace: "nowrap",
      }}
    >
      <MDBDataTable
        id="myDataTable"
        data={data}
        options={options}
        searching={true}
        sortable={true}
        striped
        bordered
        noBottomColumns={true}
      />
    </div>
  );
};

export default MyDataTable;
