import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { Modal } from "antd";
import { EditOutlined, DeleteOutlined, SaveFilled } from "@ant-design/icons";

const UserList = () => {
  const [user, setUser] = useState([]);
  const [editItemId, setEditItemId] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneno, setPhoneNo] = useState("");
  const [verifiedDropdownValue, setVerifiedDropdownValue] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/user`);
    setUser(data);
  };

  if (user.length === 0) {
    return (
      <div
        id="spinner"
        style={{ backdropFilter: "blur(4px)" }}
        class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          class="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handleEdit = (itemId, itemName, itemEmail, itemPhoneNo, itemVerified) => {
    setEditItemId(itemId);
    setName(itemName);
    setEmail(itemEmail);
    setPhoneNo(itemPhoneNo);
    setVerifiedDropdownValue(itemVerified ? "Verified" : "Not Verified");
  };

  const handleSave = async (item) => {
    try {
      Modal.confirm({
        title: "Update user",
        content: "Do you want to update this user?",
        okText: "Yes",
        cancelText: "No",
        okButtonProps: { // Add this to style the OK button
          style: { width: "80px" },
        },
        cancelButtonProps: {
          style: { width: "80px" },
        },
        onOk: async () => {
          const response = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}/auth/update/${item.id}`,
            { name, email, phoneno, verified: verifiedDropdownValue === "Verified" }
          );

          if (response.status === 200) {
            Modal.success({
              title: "User Updated Successfully!",
              content: response.data.message,
              onOk: () => {
                setEditItemId(null);
                fetchData();
              },
            });
          }
        },
      });
    } catch (err) {
      const message = err.response?.data?.message || "Something went wrong, please try again!";
      Modal.error({
        title: "Error",
        content: message,
      });
    }
    setEditItemId(null);
  };

  const handleDelete = (item) => {
    Modal.confirm({
      title: "Confirmation",
      content: "Are you sure you want to delete this user?",
      okButtonProps: { // Add this to style the OK button
        style: { width: "80px" },
      },
      cancelButtonProps: {
        style: { width: "80px" },
      },
      onOk: () => {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/auth/user/${item._id}`)
          .then(() => {
            Modal.success({
              title: "User Deleted Successfully",
              content: "User has been deleted.",
              onOk: fetchData,
            });
          })
          .catch((error) => {
            Modal.error({
              title: "Error",
              content: `Error deleting user: ${error}`,
            });
          });
      },
    });
  };

  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "verified",
        sort: "asc",
        width: 150,
      },
      {
        label: "Phone No.",
        field: "phoneno",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: "150px",
      },
    ],
    rows: user.map((item) => ({
      id: item.id,
      name:
        editItemId === item.id ? (
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        ) : (
          item.name
        ),
      email:
        editItemId === item.id ? (
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        ) : (
          item.email
        ),
      verified:
        editItemId === item.id ? (
          <select
            value={verifiedDropdownValue}
            onChange={(e) => setVerifiedDropdownValue(e.target.value)}
          >
            <option value="Verified">Verified</option>
            <option value="Not Verified">Not Verified</option>
          </select>
        ) : (
          item.verified ? "Verified" : "Not Verified"
        ),
      phoneno:
        editItemId === item.id ? (
          <input type="text" value={phoneno} onChange={(e) => setPhoneNo(e.target.value)} />
        ) : (
          item.phoneno
        ),
      action: (
        <div className="action-buttons">
          {editItemId === item.id ? (
            <SaveFilled style={{ color: "blue", fontSize: 24 }} onClick={() => handleSave(item)} />
          ) : (
            <EditOutlined
              style={{ color: "blue", fontSize: 24 }}
              onClick={() => handleEdit(item.id, item.name, item.email, item.phoneno, item.verified)}
            />
          )}
          <DeleteOutlined
            onClick={() => handleDelete(item)}
            style={{ color: "red", marginLeft: 12, fontSize: 24 }}
          />
        </div>
      ),
    })),
  };

  const options = {
    searchBox: true,
    searchBoxClass: "form-control",
    searchLabel: "Search",
    searchText: "",
    filterText: "Filter",
    responsive: true,
    responsiveSm: true,
    responsiveMd: true,
    responsiveLg: true,
    responsiveXl: true,
    rowsPerPage: 10,
    paginationLabel: ["Previous", "Next"],
  };


  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
        fontSize: "18px",
        whiteSpace: "nowrap",
      }}
    >
      <MDBDataTable
        id="myDataTable"
        data={data}
        options={options}
        searching={true}
        sortable={true}
        striped
        bordered
        noBottomColumns={true}
      />
    </div>
  );
};

export default UserList;
