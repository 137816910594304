import React, { useCallback, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Layout } from "antd";
import "./App.css";

import Home from "./dashboard/pages/Home";
import AllProperty from "./dashboard/pages/PropertyList/PropertyTable";
import PropertyEditForm from "./dashboard/pages/PropertyList/PropertyEditForm";
import ViewProperty from "./dashboard/pages/PropertyList/ViewProperty";
import FeaturedProperty from "./dashboard/pages/FeaturedProperty/FeaturedProperty";
import VerifiedProperty from "./dashboard/pages/VerifiedProperties/VerifiedProperty";
import UserList from "./dashboard/pages/UserListComponents/UserList";
import Navbar from "./dashboard/shared/Navbar";
import { AuthContext } from "./dashboard/shared/auth-context";
import Login from "./dashboard/user-components/Login";
import ContactUs from "./dashboard/pages/ContactUs";
import ChangePassword from "./dashboard/pages/ChangePassword";
import PropertyType from "./dashboard/pages/PropertyType";
import Details from "./dashboard/pages/Details";
import City from "./dashboard/pages/City";
let logoutTimer;
const { Content, Footer } = Layout;

function App() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [adminId, setAdminId] = useState(null);
  const [adminName, setAdminName] = useState(null);
  const [userlist, setUserList] = useState(false);
  const [propertylist, setPropertyList] = useState(false);
  const [featuredlist, setFeaturedList] = useState(false);
  const [verifiedlist, setVerifiedList] = useState(false);
  const [contactuslist, setContactusList] = useState(false);
  const [propertytypelist, setPropertyTypeList] = useState(false);
  const [propertydetails, setPropertyDetails] = useState(false);
  const [citylist, setCityList] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 480);
  const [isPageReloading, setIsPageReloading] = useState(false);

  const login = useCallback(
    (
      isLoggedIn,
      adminId,
      adminName,
      userlist,
      propertylist,
      featuredlist,
      verifiedlist,
      contactuslist,
      propertytypelist,
      propertydetails,
      citylist
    ) => {
      setIsLoggedIn(isLoggedIn);
      setAdminId(adminId);
      setAdminName(adminName)
      setUserList(userlist);
      setPropertyList(propertylist);
      setFeaturedList(featuredlist);
      setVerifiedList(verifiedlist);
      setContactusList(contactuslist);
      setPropertyTypeList(propertytypelist);
      setPropertyDetails(propertydetails);
      setCityList(citylist);

      const expirationTime = new Date().getTime() + 86400000; // 1 day (in milliseconds)
      localStorage.setItem(
        "AdminData",
        JSON.stringify({
          isLoggedIn,
          adminId,
          adminName,
          userlist,
          propertylist,
          featuredlist,
          verifiedlist,
          contactuslist,
          propertytypelist,
          propertydetails,
          citylist,
          expirationTime
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    setAdminId(null);
    setAdminName(null);
    setUserList(false);
    setPropertyList(false);
    setFeaturedList(false);
    setVerifiedList(false);
    setContactusList(false);
    setPropertyTypeList(false);
    setPropertyDetails(false);
    setCityList(false);
    localStorage.removeItem("AdminData");
    navigate("/login");
  }, [navigate]);


  
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("AdminData"));
    if (
      storedData &&
      storedData.adminId &&
      storedData.expirationTime > new Date().getTime()
    ) {
      login(
        storedData.isLoggedIn,
        storedData.adminId,
        storedData.adminName,
        storedData.userlist,
        storedData.propertylist,
        storedData.featuredlist,
        storedData.verifiedlist,
        storedData.contactuslist,
        storedData.propertytypelist,
        storedData.propertydetails,
        storedData.citylist,
      );
    } else {
      navigate("/login");
    }
  }, [login, navigate]);

  useEffect(() => {
    setIsLoggedIn(auth.isLoggedIn);
    setAdminId(auth.adminId);
    setAdminName(auth.adminName)
  }, [auth.isLoggedIn, auth.adminId]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = () => {
    setIsPageReloading(true);
  };

  useEffect(() => {
    if (isPageReloading) {
      setIsPageReloading(false);
      window.location.reload();
    }
  }, [isPageReloading]);
console.log(isMobileView);
  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        adminId,
        adminName,
        userlist,
        propertylist,
        featuredlist,
        verifiedlist,
        contactuslist,
        propertytypelist,
        propertydetails,
        citylist,
        login,
        logout
      }}
    >
      <div className="main">
        {isLoggedIn ? (
          <Layout className= "main-layout main12">
            <Navbar />
            <Layout>
              <Content style={{ margin: "24px 16px 0" }}>
                <div
                  className="site-layout-background"
                >
                  <Routes>
                    <Route path="/login" element={<Navigate to={`/admin/${adminId}/`} />} />
                    <Route path="/signup" element={<Navigate to={`/admin/${adminId}/`} />} />
                    <Route path="*" element={<Navigate to={`/admin/${adminId}/`}/> } />
                    <Route path="/admin/:adminId/" element={<Home />} />
                    {userlist && (
                      <Route path="/admin/:adminId/userlist" element={<UserList />} />
                    )}
                   
                    {propertylist && (
                      <Route
                        path="/admin/:adminId/propertylist"
                        element={<AllProperty />}
                      />
                    )}
                    {propertylist && (
                      <Route
                        path="/admin/:adminId/propertyreport/editpropertyform/:id"
                        element={<PropertyEditForm />}
                      />
                    )}
                    {propertylist && (
                      <Route
                        path="/admin/:adminId/viewproperty/:id"
                        element={<ViewProperty />}
                      />
                    )}
                    {featuredlist && (
                      <Route
                        path="/admin/:adminId/featuredproperties"
                        element={<FeaturedProperty />}
                      />
                    )}
                    {verifiedlist && (
                      <Route
                        path="/admin/:adminId/verifiedproperties"
                        element={<VerifiedProperty />}
                      />
                    )}
                    {contactuslist && (
                      <Route
                        path="/admin/:adminId/contactus"
                        element={<ContactUs />}
                      />
                    )}
                    {propertytypelist && (
                      <Route
                        path="/admin/:adminId/propertytype"
                        element={<PropertyType />}
                      />
                    )}
                      {propertydetails && (
                      <Route
                        path="/admin/:adminId/details"
                        element={<Details />}
                      />
                    )}
                    {citylist && (
                      <Route
                        path="/admin/:adminId/cities"
                        element={<City />}
                      />
                    )}
                    <Route
                      path="/admin/:adminId/changepassword"
                      element={<ChangePassword />}
                     />
                  </Routes>
                </div>
              </Content>
              <Footer style={{ textAlign: "center" }}>
                © 2023 TariSoft. All rights reserved.
              </Footer>
            </Layout>
          </Layout>
         ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )} 
      </div>
    </AuthContext.Provider>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
