import React, { useState, useEffect, useContext } from "react";
import { Collapse, List, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { Formik } from "formik";
import { AuthContext } from "../shared/auth-context";
import { useNavigate } from "react-router-dom";
import "./PropertyType.css";
const { Panel } = Collapse;

function PropertyType() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [propertyTypeModal, setPropertyTypeModal] = useState(false);
  const [propertySubTypeModal, setPropertySubTypeModal] = useState(false);
  const [propertyType, setPropertType] = useState("");
  const [allPropertyType, setAllPropertyType] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/property/allpropertytypes`
    );
    setAllPropertyType(data);
  };

  console.log(allPropertyType);

  if (allPropertyType.length === 0) {
    return (
      <div
        id="spinner"
        style={{ backdropFilter: "blur(4px)" }}
        class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          class="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handlepropertyTypeModalOpen = () => {
    setPropertyTypeModal(true);
  };

  const handlepropertyTypeModalClose = () => {
    setPropertyTypeModal(false);
  };
  const handlepropertySubTypeModalOpen = () => {
    setPropertySubTypeModal(true);
  };

  const handlepropertySubTypeModalClose = () => {
    setPropertySubTypeModal(false);
  };

  const handleDeletePropertyType = (propertytype) => {
    Modal.confirm({
      title: "Confirmation",
      content: "Are you sure you want to delete this Property Type?",
      okText: "Yes",
      cancelText: "No", // Updated from 'okCancel' to 'cancelText'
      okButtonProps: {
        // Add this to style the OK button
        style: { width: "80px" },
      },
      cancelButtonProps: {
        style: { width: "80px" },
      },
      onOk: () => {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/property/propertytype/`, {
            data: { propertytype }, // Send the 'propertytype' as part of the request body
          })
          .then((res) => {
            if (res.status === 200) {
              // Check the response status code appropriately
              Modal.success({
                title: "Success",
                content: "Property Type Deleted successfully.",
              });
              fetchData();
              // navigate(`/admin/${auth.adminId}/propertytype`);
            }
          })
          .catch((error) => {
            alert("error ", error);
          });
      },
      onCancel: () => {
        // Handle cancel logic if needed
      },
    });
  };

  const handleDeletePropertySubType = (propertytype, propertysubtype) => {
    Modal.confirm({
      title: "Confirmation",
      content: "Are you sure you want to delete this Property SubType?",
      okText: "Yes",
      cancelText: "No", // Updated from 'okCancel' to 'cancelText'
      okButtonProps: {
        // Add this to style the OK button
        style: { width: "80px" },
      },
      cancelButtonProps: {
        style: { width: "80px" },
      },
      onOk: () => {
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/property/propertysubtype`,
            {
              data: { propertytype, propertysubtype }, // Send the 'propertytype' as part of the request body
            }
          )
          .then((res) => {
            if (res.status === 200) {
              // Check the response status code appropriately
              Modal.success({
                title: "Success",
                content: "Property Type Deleted successfully.",
              });
              fetchData();
              // navigate(`/admin/${auth.adminId}/propertytype`);
            }
          })
          .catch((error) => {
            alert("error ", error);
          });
      },
      onCancel: () => {
        // Handle cancel logic if needed
      },
    });
  };

  return (
    <div>
      {/* <h1>
                Property Type
            </h1> */}
      <div>
        <Collapse
          size="large"
          // onChange={onChange}
          width={700}
          style={{
            marginTop: "100px",
            padding: "50px",
          }}
        >
          {allPropertyType.map((type, index) => (
            <Panel
              header={
                <div>
                  <DeleteOutlined
                    onClick={() => handleDeletePropertyType(type.propertytype)}
                    style={{ color: "red", marginRight: 12, fontSize: 24 }}
                  />
                  {type.propertytype}
                </div>
              }
              key={index}
              className="panel"
            >
              <List>
                {type.propertysubtype.map((subtype, index) => (
                  <div>
                    <List.Item>
                      <DeleteOutlined
                        onClick={() =>
                          handleDeletePropertySubType(
                            type.propertytype,
                            subtype
                          )
                        }
                        style={{ color: "red", marginRight: 12, fontSize: 24 }}
                      />
                      {index + 1}. {subtype}{" "}
                    </List.Item>
                  </div>
                ))}
              </List>
              <div className="subtypebuttondiv">
                <button
                  className="subtypebutton"
                  onClick={() => {
                    setPropertType(type.propertytype);
                    handlepropertySubTypeModalOpen();
                    console.log("hello");
                  }}
                >
                  Add SubType
                </button>
              </div>
            </Panel>
          ))}
          <div className="addType-btn">
            <button onClick={handlepropertyTypeModalOpen}>Add Type</button>
          </div>
        </Collapse>
      </div>
      <div style={{ marginTop: "50px" }}>
        <Modal
          title="Update Property Sub Type"
          visible={propertySubTypeModal}
          onCancel={handlepropertySubTypeModalClose}
          footer={null}
        >
          <Formik
            initialValues={{
              propertytype: propertyType,
              propertysubtype: [],
            }}
            validate={(values) => {
              const errors = {};
              if (!values.propertysubtype) {
                errors.propertysubtype = "Property SubType Required";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              console.log(values);
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_BASE_URL}/property/updatepropertytype`,
                  {
                    propertytype: propertyType,
                    propertysubtype: values.propertysubtype,
                  }
                );
                if (response.data.message) {
                  Modal.warning({
                    title: response.data.message,
                  });
                } else {
                  if (response.status === 200) {
                    Modal.success({
                      title: response.data,
                    });
                    setPropertySubTypeModal(false);
                    fetchData();
                  }
                }
              } catch (err) {
                const message = err.response.data.message;
                alert(err.response.data.message, err.response.status);
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                id="editmodal"
                className="w-full max-w-sm"
              >
                <div
                  className="md:flex md:items-center mb-6"
                  style={{ paddingTop: "40px" }}
                >
                  <div
                    className="col-12"
                    style={{ paddingTop: "20px", paddingBottom: "20px" }}
                  >
                    <div className="form-floating">
                      <input
                        type="text"
                        name="propertysubtype"
                        className="form-control"
                        id="propertysubtype"
                        placeholder="Property SubType"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.propertysubtype}
                      />

                      <label htmlFor="password">Property SubType</label>
                    </div>
                  </div>

                  <div
                    className="col-12"
                    style={{ paddingTop: "20px", paddingBottom: "30px" }}
                  >
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                      // disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
      </div>
      <div style={{ marginTop: "50px" }}>
        <Modal
          title="New Property Type"
          visible={propertyTypeModal}
          onCancel={handlepropertyTypeModalClose}
          footer={null}
        >
          <Formik
            initialValues={{
              propertytype: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.propertytype) {
                errors.propertytype = "Property Type Required";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              console.log(values);
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_BASE_URL}/property/addpropertytype`,
                  { propertytype: values.propertytype, propertysubtype: [] }
                );
                if (response.data.message) {
                  Modal.warning({
                    title: response.data.message,
                  });
                } else {
                  if (response.status === 200) {
                    Modal.success({
                      title: response.data,
                    });
                    setPropertyTypeModal(false);
                    fetchData();
                  }
                }
              } catch (err) {
                const message = err.response.data.message;
                alert(err.response.data.message, err.response.status);
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                id="editmodal"
                className="w-full max-w-sm"
              >
                <div
                  className="md:flex md:items-center mb-6"
                  style={{ paddingTop: "40px" }}
                >
                  <div
                    className="col-12"
                    style={{ paddingTop: "20px", paddingBottom: "20px" }}
                  >
                    <div className="form-floating">
                      <input
                        type="text"
                        name="propertytype"
                        className="form-control"
                        id="propertytype"
                        placeholder="Property Type"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.propertytype}
                      />

                      <label htmlFor="propertytype">Property Type</label>
                    </div>
                  </div>

                  <div
                    className="col-12"
                    style={{ paddingTop: "20px", paddingBottom: "30px" }}
                  >
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                      // disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
      </div>
    </div>
  );
}
export default PropertyType;
