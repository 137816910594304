import axios from "axios";
import React, {
  useCallback,
  useContext,
  UseContext,
  useEffect,
  useState,
} from "react";
import Cards from "../components/Cards";
function Home() {
  const [userCount, setUserCount] = useState();
  const [propertyCount, setPropertyCount] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/home/`); 
      setUserCount(data.authCount);
      setPropertyCount(data.propertyCount);
      
    };
    fetchData();
  }, []);
  if (!userCount && !propertyCount) {
    return (
      <div
        id="spinner"
        style={{ backdropFilter: "blur(4px)" }}
        class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          class="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  if (userCount?.length === 0 && propertyCount?.length === 0) {
    return (
      <div
        id="spinner"
        style={{ backdropFilter: "blur(4px)" }}
        class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          class="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Cards
        userCount={userCount}
        propertyCount={propertyCount}
       
      />
    </React.Fragment>
  );
}

export default Home;
