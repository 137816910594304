import React, { useState, useEffect, useContext } from "react";
import { Collapse, List, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { Formik } from "formik";
import { AuthContext } from "../shared/auth-context";
import { useNavigate } from "react-router-dom";
const { Panel } = Collapse;

function City() {
  const [cityModal, setCityModal] = useState(false);
  const [cityAreaModal, setCityAreaModal] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [city, setCity] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/property/cities`
      );
      setCityData(data);
    } catch (error) {
      // Handle the error
    }
  };

  if (cityData.length === 0) {
    return (
      <div
        id="spinner"
        style={{ backdropFilter: "blur(4px)" }}
        className="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handleCityModalOpen = () => {
    setCityModal(true);
  };

  const handleCityModalClose = () => {
    setCityModal(false);
  };

  const handleCityAreaModalOpen = () => {
    setCityAreaModal(true);
  };

  const handleCityAreaModalClose = () => {
    setCityAreaModal(false);
  };

  const handleDeleteCity = (city) => {
    Modal.confirm({
      title: "Confirmation",
      content: "Are you sure you want to delete this City?",
      okText: "Yes",
      cancelText: "No",
      okButtonProps: { // Add this to style the OK button
        style: { width: "80px" },
      },
      cancelButtonProps: {
        style: { width: "80px" },
      },
      onOk: () => {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/property/delete/city`, {
            data: { city: city },
          })
          .then((res) => {
            if (res.status === 200) {
              Modal.success({
                title: "Success",
                content: "City Deleted successfully.",
              });

              fetchData();
            }
          })
          .catch((error) => {
            alert("error ", error);
          });
      },
      onCancel: () => {
        // Handle cancel logic if needed
      },
    });
  };

  const handleDeleteCityArea = (city, cityArea) => {
    Modal.confirm({
      title: "Confirmation",
      content: "Are you sure you want to delete this City Area?",
      okText: "Yes",
      cancelText: "No",
      okButtonProps: { // Add this to style the OK button
        style: { width: "80px" },
      },
      cancelButtonProps: {
        style: { width: "80px" },
      },
      onOk: () => {
        axios
          .delete(
            `${process.env.REACT_APP_BASE_URL}/property/delete/cityarea`,
            {
              data: { city: city, cityarea: cityArea },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              Modal.success({
                title: "Success",
                content: "City Area Deleted successfully.",
              });

              fetchData();
            }
          })
          .catch((error) => {
            alert("error ", error);
          });
      },
      onCancel: () => {
        // Handle cancel logic if needed
      },
    });
  };

  const handleAddCity = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/property/addcities`,
        { city: values.city, areas: [] }
      );
      if (response.data.message) {
        Modal.warning({
          title: response.data.message,
        });
      } else {
        if (response.status === 200) {
          Modal.success({
            title: response.data,
          });
          handleCityModalClose();
          fetchData();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddCityArea = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/property/addareas`,
        { city, area: { areaText: values.cityArea } }
      );
      if (response.data.message) {
        Modal.warning({
          title: response.data.message,
        });
      } else {
        if (response.status === 200) {
          Modal.success({
            title: response.data,
          });
          handleCityAreaModalClose();
          fetchData();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Collapse
        size="large"
        width={700}
        style={{
          marginTop: "100px",
          padding: "50px",
        }}
      >
        {cityData?.map((cityInfo, index) => (
          <Panel
            header={
              <div>
                <DeleteOutlined
                  onClick={() => handleDeleteCity(cityInfo.city)}
                  style={{ color: "red", marginRight: 12, fontSize: 24 }}
                />
                {cityInfo.city}
              </div>
            }
            key={index}
            className="panel"
          >
            <List>
              {cityInfo?.cityareas?.map((area, index) => (
                <div key={area.areaText}>
                  <List.Item>
                    <DeleteOutlined
                      onClick={() =>
                        handleDeleteCityArea(cityInfo.city, area.areaText)
                      }
                      style={{ color: "red", marginRight: 12, fontSize: 24 }}
                    />
                    {index + 1}. {area.areaText}
                  </List.Item>
                </div>
              ))}
            </List>
            <div style={{  float: "right", marginTop:"-30px" }}>
              <button style={{ }}
                onClick={() => {
                  setCity(cityInfo.city);
                  handleCityAreaModalOpen();
                }}
              >
                Add City Area
              </button>
            </div>
          </Panel>
        ))}
        <div style={{ float:'right', marginTop:3 }}>
          <button onClick={handleCityModalOpen}>Add City</button>
        </div>
      </Collapse>

      <Modal
        title="New City"
        visible={cityModal}
        onCancel={handleCityModalClose}
        footer={null}
      >
        <Formik
          initialValues={{
            city: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.city) {
              errors.city = "City Required";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleAddCity(values);
            setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              id="editmodal"
              className="w-full max-w-sm"
            >
              <div
                className="md:flex md:items-center mb-6"
                style={{ paddingTop: "40px" }}
              >
                <div
                  className="col-12"
                  style={{ paddingTop: "20px", paddingBottom: "20px" }}
                >
                  <div className="form-floating">
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      id="city"
                      placeholder="City"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                    />
                    <label htmlFor="city">City</label>
                  </div>
                </div>

                <div>
                  <button className="btn btn-primary w-100 py-3" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>

      <Modal
        title="New City Area"
        visible={cityAreaModal}
        onCancel={handleCityAreaModalClose}
        footer={null}
      >
        <Formik
          initialValues={{
            cityArea: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.cityArea) {
              errors.cityArea = "City Area Required";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleAddCityArea(values);
            setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              id="editmodal"
              className="w-full max-w-sm"
            >
              <div
                className="md:flex md:items-center mb-6"
                style={{ paddingTop: "40px" }}
              >
                <div
                  className="col-12"
                  style={{ paddingTop: "20px", paddingBottom: "20px" }}
                >
                  <div className="form-floating">
                    <input
                      type="text"
                      name="cityArea"
                      className="form-control"
                      id="propertytype"
                      placeholder="City Area"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cityArea}
                    />
                    <label htmlFor="propertytype">City Area</label>
                  </div>
                </div>

                <div
                  className="col-12"
                  style={{ paddingTop: "20px", paddingBottom: "30px" }}
                >
                  <button className="btn btn-primary w-100 py-3" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

export default City;
