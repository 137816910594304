import React, {useState, useEffect, useContext} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Adminform.css";
import { AuthContext } from "../shared/auth-context";
import { Modal, Collapse, Switch } from "antd";
import axios from "axios";
import { Formik } from "formik";

const { Panel } = Collapse;

function ChangePassword() {
    const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/user/${auth.adminId}`);
      setUser(data);
    };
    fetchData();
  }, []);

  return (
    <div>
      <div className="py-5">
        <div className="container">
          <div className="main">
            <Formik
              initialValues={{
                email: "",
                oldpassword: "",
                newpassword: "",
                confirmpassword: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.oldpassword) {
                  errors.oldPassword = "OldPassword Required";
                }

                if (!values.newpassword) {
                  errors.newpassword = "Password Required";
                } else if (values.oldpassword === values.newpassword) {
                    errors.newpassword = "New password should be diffrent from Old password"
                }

                if (!values.confirmpassword) {
                  errors.confirmpassword = "Confirm Password Required";
                } else if (values.newpassword !== values.confirmpassword) {
                  errors.confirmpassword = "Invalid Confirm Password";
                }

                return errors;
              }}
              onSubmit={async (values, actions) => {
                try {
                  const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/auth/admin/updatepassword/${auth.adminId}`,
                    {
                      oldpassword: values.oldpassword,
                      newpassword: values.newpassword,
                    }
                  );
                  if(response.data.message) {
                    Modal.warning({
                      title: response.data.message
                    })
                  } else {
                    if (response.status === 200) {
                      Modal.success({
                        title: response.data,
                      })
                      navigate(`/admin/${auth.adminId}/adminlist`);
                    }
                  }

                  
                } catch (error) {
                  alert(
                    error.response.data.message,
                    error.response.status
                  );
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <div>
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="edit_property" style={{marginTop:"50px"}}>
                      
                      <div className="form-field">
                        <label>Old password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="oldpassword"
                          name="oldpassword"
                          placeholder="Enter Old Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.oldpassword}
                        />
                        <p style={{ color: "red" }}>
                          {errors.oldpassword && touched.oldpassword && errors.oldpassword}
                        </p>
                      </div>
                      <div className="form-field">
                        <label>New password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="newpassword"
                          name="newpassword"
                          placeholder="Enter New Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newpassword}
                        />
                        <p style={{ color: "red" }}>
                          {errors.newpassword && touched.newpassword && errors.newpassword}
                        </p>
                      </div>
                      <div className="form-field">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="confirmpassword"
                          name="confirmpassword"
                          placeholder="Enter Confirm Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmpassword}
                        />
                        <p style={{ color: "red" }}>
                          {errors.confirmpassword && touched.confirmpassword && errors.confirmpassword}
                        </p>
                      </div>
                      
                      <div className="Submit">
                        <button
                          className="btn btn-primary py-2 action_submit_buttons"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
