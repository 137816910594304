import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import "datatables.net-dt/css/jquery.dataTables.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { EyeOutlined, EditOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { Switch } from "antd";
import "antd/dist/antd.css";

const MyDataTable = () => {
  const [contactUs, setContactUs] = useState([]);
  const [editItemId, setEditItemId] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [usermessage, setUserMessage] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const navigate = useNavigate();

  useEffect(() => { 
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/contactus/allcontact`); 
    setContactUs(data);
  };
 


  if (contactUs.length === 0) {
    return (
      <div
        id="spinner"
        style={{ backdropFilter: "blur(4px)" }}
        class="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div
          class="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handleEdit = (itemId, itemName, itemEmail, itemSubject, itemMessage) => { 
    setEditItemId(itemId);
    setName(itemName);
    setEmail(itemEmail);
    setSubject(itemSubject);
    setUserMessage(itemMessage);
  };

  const handleSave = (itemId) => {
    try {
      Modal.confirm({
        title: "Update user",
        content: "Do you want to update this user?",
        okText: "Yes",
        cancelText: "No",
        okButtonProps: { // Add this to style the OK button
          style: { width: "80px" },
        },
        cancelButtonProps: {
          style: { width: "80px" },
        },
        onOk: async () => {
          // Perform the user verification
          const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/contactus/updatecontact/${itemId}`, {
            name,
            email,
            subject,
            usermessage,
          }); 
          if (response.status === 200) {
            fetchData();
            Modal.success({
              title: "User Updated Successfully!",
              content: response.data.message,
              onOk: () => { 
                setEditItemId(null);
              },
            });
          }
        },
      });
    } catch (err) {
      const message = err.response.data.message || "Something went wrong, please try again!";
      alert(err.response.data.message, err.response.status);
    }
    setEditItemId(null);
  };

  const handleDelete = (itemId) => {
    alert(itemId);
    Modal.confirm({
      title: "Confirmation",
      content: "Are you sure you want to delete this user?",
      okText: "Yes",
      cancelText: "No",
      okButtonProps: { // Add this to style the OK button
        style: { width: "80px" },
      },
      cancelButtonProps: {
        style: { width: "80px" },
      },
      onOk: () => {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/property/${itemId}`)
          .then((res) => { 
            if (res.status) {
              Modal.success({
                title: "Success",
                content: "Property Deleted successfully.",
              });
              fetchData();
              // navigate(`/propertylist`);
            }
          })
          .catch((error) => {
            alert("error", error);
          });
      },
      onCancel: () => {
        // Handle cancel logic if needed
      },
    });
  };

  const handleView = (item) => {
    setModalContent(item);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const mapPropertyData = () =>
  contactUs.map((item) => ({
    id: item.id,
    name:
      editItemId === item.id ? (
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
      ) : (
        item.name
      ),
    email:
      editItemId === item.id ? (
        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
      ) : (
        item.email
      ),
    subject:
      editItemId === item.id ? (
        <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
      ) : (
        item.subject
      ),
    usermessage:
      editItemId === item.id ? (
        <textarea type="text" value={usermessage} onChange={(e) => setUserMessage(e.target.value)} />
      ) : (
        item.message
      ),
    action: (
      <div className="action-buttons">
        <EyeOutlined
          style={{ color: "green", marginRight: 12, fontSize: 24 }}
          onClick={() => handleView(item)}
        />
        </div>)
    //     {editItemId === item.id ? (
    //       <SaveOutlined
    //         style={{ color: "blue", fontSize: 24 }}
    //         onClick={() => handleSave(item.id)}
    //       />
    //     ) : (
    //       <EditOutlined
    //         style={{ color: "blue", fontSize: 24 }}
    //         onClick={() =>
    //           handleEdit(
    //             item.id,
    //             item.name,
    //             item.email,
    //             item.subject,
    //             item.message // Update this line to use item.message instead of item.usermessage
    //           )
    //         }
    //       />
    //     )}
    //     <DeleteOutlined
    //       onClick={() => handleDelete(item.id)}
    //       style={{ color: "red", marginLeft: 12, fontSize: 24 }}
    //     />
    //   </div>
    // ),
    // viewed: (
    //   <Switch
    //     checked={item.viewed}
    //     // onChange={() => handleFeature(item)}
    //   />
    // ),
  }));

  const data = {
    columns: [
      // {
      //   label: "ID",
      //   field: "id",
      //   sort: "asc",
      //   width: "70px",
      // },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: "300px",
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: "200px",
      },
      {
        label: "Subject",
        field: "subject",
        sort: "asc",
        width: "200px",
      },
      {
        label: "Message",
        field: "usermessage",
        sort: "asc",
        width: "200px",
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: "150px",
      },
    //   {
    //     label: "Viewed",
    //     field: "viewed",
    //     sort: "disabled",
    //     width: "100px",
    //   },
    ],
    rows: mapPropertyData(),
  };

  const options = {
    searchBox: true,
    searchBoxClass: "form-control",
    searchLabel: "Search",
    searchText: "",
    filterText: "Filter",
    responsive: true,
    responsiveSm: true,
    responsiveMd: true,
    responsiveLg: true,
    responsiveXl: true,
    paginationLabel: ["Previous", "Next"],
  };

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
        fontSize: "18px",
        whiteSpace: "nowrap",
      }}
    >
      <MDBDataTable
        id="myDataTable"
        data={data}
        options={options}
        searching={true}
        sortable={true}
        striped
        bordered
        noBottomColumns={true}
      />

      <Modal
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={null}
        title="View Contact"
      >
        <div>
          <p>ID: {modalContent.id}</p>
          <p>Name: {modalContent.name}</p>
          <p>Email: {modalContent.email}</p>
          <p>Subject: {modalContent.subject}</p>
          <p>Message: {modalContent.message}</p>
        </div>
      </Modal>
    </div>
  );
};

export default MyDataTable;
