import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  adminId: null,
  adminName: null,
  userlist: false, 
  propertylist: false, 
  featuredlist: false, 
  verifiedlist: false, 
  contactuslist: false,
  propertytypelist: false,
  propertydetails: false,
  citylist: false,
  login: () => {},
  logout: () => {},
});