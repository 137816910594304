import React, { Fragment, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Form } from "antd";
import styles from "./FormStyling.module.css";
import axios from "axios";
import ErrorModal from "../shared/ErrorModal";
import { AuthContext } from "../shared/auth-context";

const Login = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const [form] = Form.useForm();
  const [error, setError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);
  

  const errorHandler = () => {
    setError(null);
  };

  const handleLogin = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/admin/login`,
        {
          email: values.email,
          password: values.password,
        }
      );
      console.log(response);
      if(response.data.message) {
        Modal.warning({
          title: response.data.message
        })
      } else {
        if(response.status === 200) {
          Modal.success({
            title: response.data
          })
          auth.login(
            response.data.isLoggedIn,
            response.data.user._id,
            response.data.user.name,
            response.data.user.userlist,
            response.data.user.propertylist,
            response.data.user.featuredlist,
            response.data.user.verifiedlist,
            response.data.user.contactuslist,
            response.data.user.propertytypelist,
            response.data.user.detailtypelist,
            response.data.user.citylist,
          );
        navigate(`/admin/${response.data.user._id}/`);

        }
      }
     
    } catch (err) {
      console.log(err);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      {error && (
        <ErrorModal
          error={error}
          errorTitle="An Error Occurred!"
          onClear={errorHandler}
        />
      )}
      <Modal
        title={
          <div style={{textAlign:"center"}}>Login</div>
        }
        closable={false}
        visible={isModalVisible}
        // onCancel={handleCancel}
        footer={null}
        header={null}
        className={styles.main}
       
      >
        <div className={styles.container}>
          

          <div className={styles.title}>
            <h2>Login Required</h2>
          </div>
          <Form
            form={form}
            autoComplete="off"
            labelCol={{ span: 10 }}
            onFinish={handleLogin}
          >
            <div className={styles["form-control"]}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Email",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
                hasFeedback
              >
                <input type="email" placeholder="Enter Your Email" />
              </Form.Item>
            </div>

            <div className={styles["form-control"]}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                  {
                    min: 8,
                  },
                ]}
                hasFeedback
              >
                <input type="password" placeholder="Enter Your Password" />
              </Form.Item>
            </div>
            <div className={styles["form-actions"]}>
              <Form.Item>
                <button  type="submit">Login</button>
              </Form.Item>
            </div>
          </Form>
          
        </div>
      </Modal>
    </Fragment>
  );
};

export default Login;
